import React from "react";
import { Helmet } from "react-helmet";

/** ============================================================================
 * @component
 * Custom scripts that must appear in the <head> of the application.
 */
const Scripts = () => (
  <>
    <Helmet>
      <script type="text/javascript">
        console.log(`[scripts] Helmet connected`);
      </script>
    </Helmet>
  </>
);

export default Scripts;
