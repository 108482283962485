/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { useShopify } from "~hooks";
import { SquareImage, Go } from "~components";

const CartItem = ({ item, index, product }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { decreaseQuantityByCartIndex, increaseQuantityByCartIndex } =
    useShopify();

  // ---------------------------------------------------------------------------
  // variables

  const price = parseFloat(
    product?.variant?.sourceData?.priceV2?.amount
  ).toFixed(2);

  if (!price) {
    return <></>;
  }

  const image = product?.gallery?.[0];

  // ---------------------------------------------------------------------------
  // render

  return (
    <article
      css={css`
        width: 100%;
        position: relative;
        display: flex;
        margin-bottom: 0.5rem;
      `}
    >
      <div
        css={css`
          width: 25%;
          height: 100%;
          background: var(--color-mud);
        `}
      >
        <Go to={`/products/${product?.handle}`}>
          <SquareImage image={image} />
        </Go>
      </div>

      <div
        css={css`
          width: 75%;
          position: relative;
          padding-left: 0.75rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            width: 100%;
            position: relative;
            display: flex;
            align-items: start;
          `}
        >
          <div
            css={css`
              width: 60%;
              position: relative;
            `}
          >
            <p className="text-button">{product.title}</p>

            {product?.variant?.title !== `Default Title` && (
              <p className="text-button">{product.variant.title}</p>
            )}
          </div>

          <div
            css={css`
              width: 40%;
              position: relative;
              text-align: right;
            `}
          >
            <p className="text-button">${price} AUD</p>
          </div>
        </div>

        <div
          css={css`
            width: 100%;
            position: relative;
            display: flex;
            align-items: start;
          `}
          className="text-button"
        >
          <button
            type="button"
            css={css`
              width: 1.75rem;
              height: 2rem;
              position: relative;
              display: block;
            `}
            onClick={() => {
              decreaseQuantityByCartIndex(index);
            }}
          >
            <span className="text-button">–</span>
          </button>

          <input
            css={css`
              width: 2rem;
              height: 2rem;
              border: none;
              text-align: center;

              // this might not be required
              pointer-events: none;

              -moz-appearance: textfield;
              appearance: none;
              background: transparent;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            `}
            className="text-button"
            disabled
            onChange={(e) => {
              // setQuantityByCartIndex(e.currentTarget.value, index);
            }}
            value={item?.quantity}
            type="number"
          />

          <button
            type="button"
            css={css`
              width: 1.75rem;
              height: 2rem;
              position: relative;
              display: block;
            `}
            onClick={() => {
              increaseQuantityByCartIndex(index);
            }}
          >
            <span className="text-button">+</span>
          </button>
        </div>
      </div>
    </article>
  );
};

export default CartItem;
