import React from "react";
import { css, Global } from "@emotion/react";

const COLORS = {
  transparent: `transparent`,
  //
  black: `#000000`,
  "grey-30": `#373737`,
  "grey-20": `#BCBCBC`,
  "grey-10": `#E2E2E2`,
  purple: `#9161F2`,
  mud: `#BFBDAB`,
  lime: `#DEFE8E`,
  white: `#FFFFFF`,
  yellow: `#FECE8E`
};

const Colors = () => (
  <Global
    styles={[
      css`
        :root {
          ${Object.keys(COLORS).map(
            (colorKey) => `
              --color-${colorKey}: ${COLORS[colorKey]};
            `
          )}
        }
      `
    ]}
  />
);

export default Colors;
