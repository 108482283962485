/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { css } from "@emotion/react";

const ScreenHeight = ({ _css, children }) => {
  const windowHeight = 0;

  //

  return (
    <section
      css={css`
        ${_css};
        height: 100vh;
        height: ${windowHeight}px;
      `}
    >
      {children}
    </section>
  );
};

export default ScreenHeight;
