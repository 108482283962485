import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import {
  Cart,
  CookieNotification,
  Footer,
  Header,
  HeightDetector,
  Newsletter,
  ReviewCreator,
  // Scripts,
  Theme
} from "~components";

/** ============================================================================
 * @component
 * Core Gatsby layout component.
 */

const Layout = ({ _css, children }) => (
  <>
    {/* <Scripts /> */}
    <Theme />
    <HeightDetector />

    <Cart />

    <Header />

    <CookieNotification />

    <Newsletter />

    <ReviewCreator />

    <main
      id="root"
      css={css`
        ${_css};
      `}
    >
      {children}
    </main>

    <Footer />
  </>
);

Layout.defaultProps = {
  _css: ``
};

Layout.propTypes = {
  _css: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Layout;
