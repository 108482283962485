/* eslint-disable react/no-danger */
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useApp, useSanityGlobals } from "~hooks";
import { Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

/** ============================================================================
 * @component
 * Global nav.
 */

const Container = styled.div`
  transition: 0.3s var(--cubic-easing) transform;

  transform: translate3d(0, ${({ active }) => (active ? `0;` : `100%`)}, 0);

  width: 100vw;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 30;
  cursor: pointer;
  background: var(--color-lime);
  color: black;
`;

const CookieNotification = () => {
  const { cookiesActive, setCookiesActive } = useApp();

  const { cookiesMessage } = useSanityGlobals();

  //

  return (
    <>
      {cookiesMessage && (
        <Container
          active={cookiesActive}
          role="presentation"
          onClick={() => setCookiesActive(false)}
        >
          <Grid>
            <div
              css={css`
                grid-column: span 12 / span 12;
                position: relative;
              `}
            >
              <p
                css={css`
                  grid-column: span 12 / span 12;
                  padding: 0.5rem 2rem 0.5rem 0;

                  ${MEDIA_QUERIES?.desktop} {
                    padding: 0.5rem 0;
                  }
                `}
                className="b3"
              >
                <span dangerouslySetInnerHTML={{ __html: cookiesMessage }} />
              </p>

              <div
                css={css`
                  width: 3rem;
                  position: absolute;
                  top: 0;
                  right: -0.75rem;
                  bottom: 0;
                  z-index: 10;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span {
                    display: block;
                  }

                  ${MEDIA_QUERIES?.desktop} {
                    right: 0;
                  }
                `}
              >
                <span className="b1">×</span>
              </div>
            </div>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default CookieNotification;
