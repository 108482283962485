/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";
import { GRID_PADDING_REM } from "~components/_common/Grid/index.jsx";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Content = styled.div`
  width: calc(100% - ${GRID_PADDING_REM}rem);
  height: auto;
  position: relative;
  z-index: 40;
  padding: 0.667rem 0.667rem 0.667rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-white);
  color: var(--color-black);
  box-shadow: 0px 2px 4px #0000001a;

  ${MEDIA_QUERIES?.desktop} {
    width: 38rem;
    height: auto;
    padding: 1rem;
  }
`;

const Form = styled.form`
  grid-column: span 12 / span 12;
  grid-column-start: 1;
  position: relative;
  padding: 2.5rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 6 / span 6;
    grid-column-start: 4;
  }
`;

const FormInput = styled.input`
  width: 100%;
  height: 36px;
  position: relative;
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  margin: 0 0 0.5rem;

  ${MEDIA_QUERIES?.desktop} {
    height: 42px;
    margin: 0 0 0.5rem;
  }
`;

const FormSelect = styled.select`
  width: 100%;
  height: 36px;
  position: relative;
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  margin: 0 0 0.5rem;

  ${MEDIA_QUERIES?.desktop} {
    height: 42px;
    margin: 0 0 0.5rem;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  height: 7rem;
  border: 0px;
  border-bottom: 1px solid black;
  padding: 0 0 6px;
  margin: 0.5rem 0 1rem;
  resize: none;

  ${MEDIA_QUERIES?.desktop} {
    margin: 0.75rem 0 1rem;
  }
`;

const FormButton = styled.button`
  width: 223px;
  height: 36px;
  position: relative;
  display: block;
  margin-top: 1.75rem;
  border: 1px solid black;
  border-radius: 2rem;

  ${MEDIA_QUERIES?.hoverable} {
    width: 150px;
    height: 44px;
    margin-top: 0.4rem;
    border-radius: 100px;

    &:hover {
      background: var(--color-mud);
      border: 1px solid transparent;
    }
  }
`;

const CloseButton = styled.button`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
`;

const ReviewCreator = () => {
  // ---------------------------------------------------------------------------
  // static query

  const { allSanityShopifyProduct } = useStaticQuery(graphql`
    query ReviewCreator {
      allSanityShopifyProduct {
        edges {
          node {
            title
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const { reviewOverlayActive, setReviewOverlayActive } = useApp();

  const [formData, setFormData] = useState({
    subject: `New Review`
  });
  const [status, setStatus] = useState({
    submitting: false,
    submitted: false
  });

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (status?.submitting || status?.submitted) {
      return false;
    }

    setStatus({
      submitted: false,
      submitting: true
    });

    //

    fetch(`/api/gmail`, {
      method: `post`,
      headers: {
        "content-type": `application/json`
      },
      body: JSON.stringify(formData)
    }).then((res) => {
      setStatus({
        submitted: true,
        submitting: false
      });

      setReviewOverlayActive(false);
    });

    return false;
  };

  // ---------------------------------------------------------------------------
  // render

  let submitText = `Submit`;

  if (status?.submitting) {
    submitText = `Working...`;
  } else if (status?.submitted) {
    submitText = `Thank you!`;
  }

  return (
    <Wrapper>
      <Content
        className="modal"
        css={css`
          transition: 0.5s var(--cubic-easing) opacity,
            0.5s var(--cubic-easing) transform;

          opacity: ${reviewOverlayActive ? `1` : `0`};
          pointer-events: ${reviewOverlayActive ? `auto` : `none`};
          transform: translate3d(0, ${reviewOverlayActive ? `0` : `1rem`}, 0);
        `}
      >
        <div>
          <Form
            css={css`
              opacity: ${status?.submitting || status?.submitted ? 0.5 : 1};
            `}
            onSubmit={handleSubmit}
          >
            <FormInput
              name="name"
              className="b3"
              onChange={onChange}
              placeholder="Name*"
              readOnly={status?.submitting || status?.submitted}
              required
            />

            <FormInput
              name="email"
              className="b3"
              type="email"
              onChange={onChange}
              placeholder="Email*"
              readOnly={status?.submitting || status?.submitted}
              required
            />

            <FormSelect
              className="b3"
              readOnly={status?.submitting || status?.submitted}
              required
            >
              <option disabled selected>
                -- Choose Product --
              </option>
              {allSanityShopifyProduct.edges.map(({ node }) => {
                const { title } = node;
                const key = `review-creator-${title}`;

                if (!title || title === ``) {
                  return <React.Fragment key={key} />;
                }

                return (
                  <option key={key} value={title}>
                    {title}
                  </option>
                );
              })}
            </FormSelect>

            <FormInput
              name="rating"
              className="b3"
              onChange={onChange}
              placeholder="Rating (1-5)*"
              type="number"
              min="1"
              max="5"
              readOnly={status?.submitting || status?.submitted}
              required
            />

            <FormTextArea
              name="review"
              className="b3"
              onChange={onChange}
              placeholder="Review*"
              readOnly={status?.submitting || status?.submitted}
              required
            />

            <FormButton className="text-button" type="submit">
              {submitText}
            </FormButton>
          </Form>
        </div>

        <CloseButton
          type="button"
          className="modal-close"
          onClick={() => setReviewOverlayActive(false)}
        >
          <span className="text-cross">x</span>
        </CloseButton>
      </Content>
    </Wrapper>
  );
};

export default ReviewCreator;
