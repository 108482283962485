import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

const Video = ({ _css, autoPlay, loop, muted, playsInline, poster, src }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { ref, inView } = useInView();

  const videoRef = useRef();

  const [loaded, setLoaded] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (videoRef?.current && !loaded) {
      setLoaded(true);
    }
  }, [videoRef.current]);

  useEffect(() => {
    if (!loaded || !videoRef?.current) {
      return;
    }

    videoRef.current.onpause = () => {
      if (videoRef?.current) {
        videoRef.current.playing = false;
      }
    };

    videoRef.current.onplaying = () => {
      if (videoRef?.current) {
        videoRef.current.playing = true;
      }
    };
  }, [loaded]);

  useEffect(() => {
    if (!loaded || !videoRef?.current) {
      return;
    }

    const video = videoRef.current;

    if (inView && !video?.playing) {
      video.play();
    }

    if (!inView && video?.playing) {
      video.pause();
    }
  }, [inView]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div ref={ref}>
      <video
        css={_css}
        ref={videoRef}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        playsInline={playsInline}
        poster={poster}
      >
        <source src={src} />
      </video>
    </div>
  );
};

Video.defaultProps = {
  _css: {},
  autoPlay: false,
  loop: true,
  muted: true,
  playsInline: true,
  poster: null,
  src: ``
};

Video.propTypes = {
  _css: PropTypes.shape({}),
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  playsInline: PropTypes.bool,
  poster: PropTypes.string,
  src: PropTypes.string
};

export default Video;
