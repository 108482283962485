import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "~utils/css";

const Button = ({ _css, border, onClick, text }) => {
  const [hovering, setHovering] = useState(false);

  //

  return (
    <button
      type="button"
      css={css`
        ${_css};

        transition: 0.3s var(--cubic-easing) background;

        height: 2.5rem;
        border-radius: 2rem;
        background: var(--color-white);
        color: var(--color-black);
        border: 1px solid ${border || `transparent`};

        ${MEDIA_QUERIES?.hoverable} {
          &:hover {
            background: var(--color-mud);
            border: 1px solid transparent;
          }
        }

        ${MEDIA_QUERIES?.desktop} {
          height: 3rem;
        }
      `}
      onClick={onClick}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <span
        className="text-button"
        css={css`
          //
        `}
      >
        {text}
      </span>
    </button>
  );
};

Button.defaultProps = {
  _css: [],
  onClick: () => {},
  text: `Submit`,
  border: `transparent`
};

Button.propTypes = {
  _css: PropTypes.shape({}),
  onClick: PropTypes.func,
  text: PropTypes.string,
  border: PropTypes.string
};

export default Button;
