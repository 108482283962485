import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";
import { Go, Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { useWindowDimensions } from "~hooks";

const Footer = () => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query Footer {
      sanityGlobals {
        footerLinks {
          text
          url
        }

        footerNote
      }
    }
  `);

  const { footerLinks, footerNote } = sanityGlobals;
  const { isDesktop } = useWindowDimensions();
  //

  const copyright = (
    <div
      css={css`
        grid-column: span 12 / span 12;
        margin-top: 1rem;
        text-align: center;

        ${MEDIA_QUERIES?.desktop} {
          grid-column: span 3 / span 3;
          grid-column-start: 1;
          margin-top: 0;
          text-align: left;
        }
      `}
    >
      <Go to="/">
        <h4
          css={css`
            padding: 0.5rem 0;
            display: inline-block;
          `}
          className="text-button"
        >
          © 2021 Self Care™ Originals
        </h4>
      </Go>
    </div>
  );

  return (
    <footer
      css={css`
        width: 100%;
        position: relative;
        display: block;
        padding: 2.5rem 0 0.1rem;
        background: var(--color-white);
        color: var(--color-black);
        z-index: 1;

        ${MEDIA_QUERIES?.desktop} {
          padding: 1.5rem 0 0.5rem;
        }
      `}
    >
      <Grid>
        <div
          css={css`
            grid-column: span 12 / span 12;
            margin-bottom: 1rem;
            padding: 0 0.4rem;

            ${MEDIA_QUERIES?.desktop} {
              grid-column: span 8 / span 8;
              grid-column-start: 3;
              padding: 0;
            }
          `}
        >
          <p
            css={css`
              text-align: center;
            `}
            className="text-button"
          >
            {footerNote}
          </p>
        </div>

        {isDesktop && copyright}

        <div
          css={css`
            grid-column: span 12 / span 12;

            ${MEDIA_QUERIES?.desktop} {
              grid-column: span 6 / span 6;
            }
          `}
        >
          <ul
            css={css`
              width: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding: 2.5rem 0 2rem;

              ${MEDIA_QUERIES?.desktop} {
                flex-direction: row;
                padding: 0;
                flex-wrap: wrap;
              }
            `}
          >
            {footerLinks?.[0] &&
              footerLinks.map(({ text, url }) => {
                const key = text;

                return (
                  <li key={key}>
                    <Go
                      to={url}
                      _css={[
                        css`
                          ${MEDIA_QUERIES?.hoverable} {
                            &:hover {
                              text-decoration: underline;
                            }
                          }
                        `
                      ]}
                    >
                      <p
                        className="text-button"
                        css={css`
                          padding: 0.1rem 0;

                          ${MEDIA_QUERIES?.desktop} {
                            padding: 0.3rem 0;
                            margin: 0 0.75rem;
                            white-space: nowrap;
                          }
                        `}
                      >
                        {text}
                      </p>
                    </Go>
                  </li>
                );
              })}
          </ul>
        </div>

        {!isDesktop && copyright}
      </Grid>
    </footer>
  );
};

export default Footer;
