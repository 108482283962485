import React from "react";
import { css, Global } from "@emotion/react";
import { MEDIA_QUERIES } from "~utils/css";

import quadrantWoff2 from "~assets/fonts/quadrant-text-regular.woff2";
import quadrantWoff from "~assets/fonts/quadrant-text-regular.woff";
import quadrantTtf from "~assets/fonts/quadrant-text-regular.ttf";
import quadrantItalicWoff2 from "~assets/fonts/quadrant-text-regular-italic.woff2";
import quadrantItalicWoff from "~assets/fonts/quadrant-text-regular-italic.woff";
import quadrantItalicTtf from "~assets/fonts/quadrant-text-regular-italic.ttf";
import twkLausanneLightWoff2 from "~assets/fonts/twk-lausanne-light.woff2";
import twkLausanneLightWoff from "~assets/fonts/twk-lausanne-light.woff";
import twkLausanneLightTtf from "~assets/fonts/twk-lausanne-light.ttf";

const SANS_FALLBACKS = `ui-sans-serif, system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif`;
const QUADRANT_TEXT = `"Quadrant Text"`;
const TWK_LAUSANNE = `"TWK Lausanne"`;
// const QUADRANT_TEXT = `"Quadrant Text", ${SANS_FALLBACKS}`;
// const TWK_LAUSANNE = `"TWK Lausanne", ${SANS_FALLBACKS}`;

/** ============================================================================
 * @component
 * Typography files and settings.
 */
const Fonts = () => (
  <>
    <Global
      styles={[
        css`
          @font-face {
            font-family: ${QUADRANT_TEXT};
            src: url(${quadrantWoff}) format("woff"),
              url(${quadrantWoff2}) format("woff2"),
              url(${quadrantTtf}) format("truetype");
            font-display: block;
            font-weight: normal;
            font-style: normal;
          }

          @font-face {
            font-family: ${QUADRANT_TEXT};
            src: url(${quadrantItalicWoff}) format("woff"),
              url(${quadrantItalicWoff2}) format("woff2"),
              url(${quadrantItalicTtf}) format("truetype");
            font-display: block;
            font-weight: normal;
            font-style: italic;
          }

          @font-face {
            font-family: ${TWK_LAUSANNE};
            src: url(${twkLausanneLightWoff}) format("woff"),
              url(${twkLausanneLightWoff2}) format("woff2"),
              url(${twkLausanneLightTtf}) format("truetype");
            font-display: block;
            font-weight: 300;
            font-style: normal;
          }

          //

          .h1 {
            font-family: ${TWK_LAUSANNE};
            font-weight: 300;
            font-size: 18px;
            line-height: 38px;
          }

          .h2 {
            font-family: ${TWK_LAUSANNE};
            font-weight: 300;
            font-size: 18px;
            line-height: 28px;
          }

          .h3 {
            font-family: ${TWK_LAUSANNE};
            font-weight: 300;
            font-size: 18px;
            line-height: 34px;
          }

          .h4 {
            font-family: ${TWK_LAUSANNE};
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
          }

          //

          .b1 {
            font-family: ${QUADRANT_TEXT};
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
          }

          .b2 {
            font-family: ${QUADRANT_TEXT};
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
          }

          .b3 {
            font-family: ${QUADRANT_TEXT};
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }

          .text-button {
            font-family: ${QUADRANT_TEXT};
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
          }

          .text-header-button {
            font-family: ${QUADRANT_TEXT};
            font-weight: 400;
            font-size: 10px;
            line-height: 20px;
          }

          .text-logo {
            font-family: ${QUADRANT_TEXT};
            font-weight: 400;
            font-size: 11px;
            line-height: 20px;
          }

          .text-cross {
            font-family: ${TWK_LAUSANNE};
            font-weight: 400;
            font-size: 14px;
          }

          .caption {
            font-family: ${QUADRANT_TEXT};
            font-weight: 400;
            font-size: 10px;
          }

          ${MEDIA_QUERIES?.desktop} {
            .h1 {
              font-family: ${TWK_LAUSANNE};
              font-weight: 300;
              font-size: 28px;
              line-height: 38px;
            }

            .h2 {
              font-family: ${TWK_LAUSANNE};
              font-weight: 300;
              font-size: 26px;
              line-height: 34px;
            }

            .h3 {
              font-family: ${TWK_LAUSANNE};
              font-weight: 300;
              font-size: 26px;
              line-height: 34px;
            }

            .h4 {
              font-family: ${TWK_LAUSANNE};
              font-weight: 300;
              font-size: 16px;
              line-height: 20px;
            }

            //

            .b1 {
              font-family: ${QUADRANT_TEXT};
              font-weight: 400;
              font-size: 26px;
              line-height: 36px;
            }

            .b2 {
              font-family: ${QUADRANT_TEXT};
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
            }

            .b3 {
              font-family: ${QUADRANT_TEXT};
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
            }

            .text-button {
              font-family: ${QUADRANT_TEXT};
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }

            .text-logo {
              font-family: ${QUADRANT_TEXT};
              font-weight: 400;
              font-size: 17px;
              line-height: 20px;
            }

            .text-cross {
              font-family: ${TWK_LAUSANNE};
              font-weight: 400;
              font-size: 14px;
            }

            .caption {
              font-family: ${QUADRANT_TEXT};
              font-weight: 400;
              font-size: 10px;
            }
          }
        `
      ]}
    />
  </>
);

export default Fonts;
