/* eslint-disable react/prop-types */

import React from "react";
import { PropTypes } from "prop-types";
import { css } from "@emotion/react";

const Banner = ({ text }) => {
  // ===========================================================================
  // context / ref / state

  let i;

  // ===========================================================================
  // render

  return (
    <section
      css={css`
        height: 56.25vw;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <h1 className="h1">{text}</h1>
    </section>
  );
};

Banner.propTypes = {
  text: PropTypes.string.isRequired
};

export default Banner;
