/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useState } from "react";
import PortableText from "react-portable-text";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { Button, Go, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { GRID_PADDING_REM } from "~components/_common/Grid/index.jsx";

const BodyHeading = styled.h3`
  position: relative;
  text-align: center;
  z-index: 10;
  line-height: 24px;
  padding-bottom: 0.5rem;

  ${MEDIA_QUERIES?.desktop} {
    line-height: 42px;
    padding-bottom: 0;
  }
`;

const BodyText = styled.p`
  position: relative;
  z-index: 10;
  padding-bottom: 42px;
`;

const BodyImage = styled.figure`
  width: 100%;
  position: relative;
  z-index: 10;
  padding-bottom: 18px;

  ${MEDIA_QUERIES?.desktop} {
    width: calc(100% + ${GRID_PADDING_REM}rem);
    left: -${GRID_PADDING_REM / 2}rem;
    padding-bottom: 42px;
    width: auto;
    left: auto;
  }
`;

const BodyListItem = styled.li`
  position: relative;
`;

const SanityRichText = ({ blocks, serializer }) => {
  const sanityConfig = { projectId: `qesh88mp`, dataset: `production` };

  return (
    <PortableText
      // Pass in block content straight from Sanity.io
      content={blocks}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{
        h1: (props) => (
          <BodyHeading className="h1">{props.children}</BodyHeading>
        ),

        h2: (props) => (
          <BodyHeading className="h2">{props.children}</BodyHeading>
        ),

        h3: (props) => (
          <BodyHeading className="h3">{props.children}</BodyHeading>
        ),

        h4: (props) => (
          <BodyHeading className="h4">{props.children}</BodyHeading>
        ),

        h5: (props) => (
          <BodyHeading className="h5">{props.children}</BodyHeading>
        ),

        h6: (props) => (
          <BodyHeading className="h6">{props.children}</BodyHeading>
        ),

        normal: (props) => <BodyText className="b3">{props.children}</BodyText>,

        blockquote: (props) => (
          <blockquote className="b1">
            <p>
              <span>|</span>
              <span>{props.children}</span>
            </p>
          </blockquote>
        ),

        altImage: (props) => {
          if (!props?.asset?._ref) {
            return <></>;
          }

          const imageData = getGatsbyImageData(
            props.asset._ref,
            { maxWidth: 1024 },
            sanityConfig
          );

          const imageJSX = <Image image={imageData} alt={props.altText} />;

          return <BodyImage>{imageJSX}</BodyImage>;
        },

        embed: (props) => {
          if (typeof props?.embed === `undefined`) {
            return <></>;
          }

          return (
            <div
              className="w-full relative block mt-4 mb-16"
              dangerouslySetInnerHTML={{ __html: props?.embed }}
            />
          );
        },

        ol: (props) => (
          <ol
            css={css`
              list-style-type: decimal;
              list-style-position: outside;
              padding-left: 1rem;
            `}
          >
            {props.children}
          </ol>
        ),

        li: ({ children }) => (
          <BodyListItem>
            <p className="b3">{children}</p>
          </BodyListItem>
        ),

        //

        marks: {
          link: ({ children, mark }) => {
            if (mark?.newTab) {
              return (
                <a
                  href={mark.href}
                  style={{ textDecoration: `underline !important` }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="b2"
                >
                  {children}
                </a>
              );
            }

            return (
              <a
                href={mark.href}
                style={{ textDecoration: `underline !important` }}
              >
                {children}
              </a>
            );
          }
        },

        button: (props) => {
          const buttonJSX = (
            <Button
              _css={css`
                padding-left: 4rem;
                padding-right: 4rem;
              `}
              border="var(--color-black)"
              color="black"
              text={props.children}
            />
          );

          if (props?.internal) {
            return (
              <Go to={props.url} className="article-button b2">
                {buttonJSX}
              </Go>
            );
          }

          return (
            <a
              href={props.url}
              rel="noopener noreferrer"
              target="_blank"
              className="article-button b2"
            >
              {buttonJSX}
            </a>
          );
        }
      }}
    />
  );
};

export default SanityRichText;
