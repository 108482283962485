/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Button, Image } from "~components";

const Wrapper = styled.section`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
`;

const Figure = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const SquareImage = ({ image }) => (
  <Wrapper>
    <Figure>
      <Image
        _css={css`
          width: 100%;
          max-width: none;
          height: 100%;
          max-height: none;
          display: block;
          object-fit: cover;
        `}
        image={image}
      />
    </Figure>
  </Wrapper>
);

export default SquareImage;
