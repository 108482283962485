/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Go, Image } from "~components";
import { useWindowDimensions } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const ImageContainer = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
`;

const imageCSS = css`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  object-fit: cover;
`;

/** ============================================================================
 * @component
 * Card link showing a product as a PDP link. By default the card will show the
 * first available variant, but this can be overridden by specifying one through
 * props.
 */
const ProductCard = ({ product, variant }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isDesktop } = useWindowDimensions();

  const [colorOptions, setColorOptions] = useState(null);
  const [hovering, setHovering] = useState(false);

  // ---------------------------------------------------------------------------
  // variables

  let image;
  let hoverImage;
  let price;

  if (product?.primaryImage) {
    image = product.primaryImage;
  } else if (product?.sourceData?.images?.edges?.[0]?.node) {
    image = product.sourceData.images.edges[0].node.originalSrc;
  }

  if (product?.secondaryImage) {
    hoverImage = product.secondaryImage;
  }

  if (variant?.sourceData?.priceV2?.amount) {
    price = parseFloat(variant.sourceData.priceV2.amount).toFixed(2);
  }

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!product?.options?.[1]) {
      return;
    }

    const colours = [];

    product.options.forEach(({ name, values }) => {
      const parsedName = name?.toLowerCase();

      if (
        parsedName?.toLowerCase() === `color` ||
        parsedName?.toLowerCase() === `colour`
      ) {
        values.forEach(({ value }) => {
          if (!colours?.includes(value?.toLowerCase())) {
            colours.push(value);
          }
        });
      }
    });

    if (colours?.[0]) {
      setColorOptions(colours);
    }
  }, [product]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      {variant && (
        <article
          css={css`
            width: 100%;
            position: relative;
            display: block;
            text-align: center;
          `}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <Go to={`/products/${product.handle}`}>
            <div
              css={css`
                width: 100%;
                position: relative;
                padding-bottom: 100%;
                display: block;
              `}
            >
              {image && (
                <ImageContainer>
                  <Image _css={imageCSS} image={image} />
                </ImageContainer>
              )}

              {hoverImage && (
                <ImageContainer
                  css={css`
                    transition: 0.5s var(--cubic-easing) opacity;
                    opacity: ${hovering ? 1 : 0};
                    z-index: 10;
                  `}
                >
                  <Image _css={imageCSS} image={hoverImage} />
                </ImageContainer>
              )}
            </div>

            <h3
              css={css`
                margin: 0.8rem 0 0.25rem;
                text-transform: uppercase;

                ${MEDIA_QUERIES?.desktop} {
                  margin: 0.75rem 0 0.25rem;
                }
              `}
              className={isDesktop ? `h4` : `text-cross`}
            >
              {product?.title || `Product`}
            </h3>
            <h3 className="b3">${price} AUD</h3>
          </Go>
        </article>
      )}
    </>
  );
};

export default ProductCard;
