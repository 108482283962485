/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable react/button-has-type */

import React, { useRef, useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useWindowDimensions } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

/** ============================================================================
 * @css
 */
const BUTTON_HEIGHT = `2rem`;

const Container = styled.section`
  transition: 0.5s ease max-height;

  max-height: ${({ active, activeHeight }) =>
    active ? `calc(${BUTTON_HEIGHT} + ${activeHeight}px)` : BUTTON_HEIGHT};

  width: 100%;
  position: relative;
  overflow: hidden;
`;
//

/** ============================================================================
 * @component
 */
const Expander = ({
  active = false,
  className = ``,
  label,
  labelOpacity = 1,
  content,
  onClick = () => {}
}) => {
  const { isDesktop } = useWindowDimensions();

  const contentRef = useRef();

  const [contentHeight, setContentHeight] = useState(0);

  //

  useEffect(() => {
    if (!contentRef?.current) {
      return;
    }

    setContentHeight(contentRef.current.getBoundingClientRect().height);
  }, [contentRef]);

  //

  return (
    <Container
      className={className}
      active={active}
      activeHeight={contentHeight}
    >
      <button
        type="button"
        onClick={onClick}
        css={css`
          width: 100%;
          height: ${BUTTON_HEIGHT};
          position: relative;
          display: block;
          text-transform: uppercase;
        `}
      >
        <div
          css={css`
            transition: 0.3s var(--cubic-easing) opacity;

            width: 100%;
            position: relative;
            opacity: ${labelOpacity};
            text-align: center;
          `}
        >
          <span className={isDesktop ? `h4` : `text-cross`}>{label}</span>
        </div>
      </button>

      <div
        css={css`
          position: relative;
          overflow-y: scroll;
          padding: 0 0 2.5rem;
          white-space: pre-wrap;

          ${MEDIA_QUERIES?.desktop} {
            padding: 0.1rem 0 3rem;
            max-height: ;
          }
        `}
      >
        <div
          ref={contentRef}
          css={css`
            position: relative;
          `}
        >
          {content()}
        </div>
      </div>
    </Container>
  );
};

export default Expander;
