import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useInView } from "react-intersection-observer";

const AppearOnScroll = ({ children }) => {
  const { ref, inView } = useInView();

  return (
    <div
      ref={ref}
      css={css`
        transition: 750ms opacity var(--cubic-easing),
          750ms transform var(--cubic-easing);
        transition-delay: ${inView ? `200` : `0`}ms;
        transform: translate3d(0, ${inView ? `0` : `2rem`}, 0);
        opacity: ${inView ? `1` : `0`};
      `}
    >
      {children}
    </div>
  );
};

// AppearOnScroll.defaultProps = {
// };

AppearOnScroll.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppearOnScroll;
