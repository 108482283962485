/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { AppearOnScroll } from "~components";
import { capitalizeString } from "~utils/helpers";

//
// todo: uuidv4

/** ============================================================================
 * @component
 * Core manifest of available sections inherited from Sanity.
 */
const PageBuilder = ({ content, sections: reactComponents }) => {
  const { sections } = content || [];

  if (!sections?.[0]) {
    return <></>;
  }

  const jsx = [];

  sections.forEach((section, sectionIndex) => {
    const sectionKey = `sanity-pagebuilder-section-${sectionIndex}`;
    const Section = reactComponents?.[`${capitalizeString(section?._type)}`];

    jsx.push(
      <React.Fragment key={sectionKey}>
        {Section && (
          <AppearOnScroll>
            <Section data={section} />
          </AppearOnScroll>
        )}
      </React.Fragment>
    );
  });

  return jsx;
};

export default PageBuilder;

export const query = graphql`
  fragment PageBuilderFragment on SanityPage {
    pagebuilder {
      sections {
        ...AccordionFragment
        ...ArticleBannerFragment
        ...ArticleGridFragment
        ...AutoFAQFragment
        ...CollectionBannerFragment
        ...CollectionCTAFragment
        ...ContactFormFragment
        ...FullImageFragment
        ...ImageBannerFragment
        ...ImageLinkFragment
        ...NewsletterFragment
        ...PressGridFragment
        ...PressMarqueeFragment
        ...ProductGridFragment
        ...ReviewsFragment
        ...RichTextFragment
        ...ShareMarqueeFragment
        ...SmallTextFragment
      }
    }
  }

  fragment ProductBuilderFragment on SanityShopifyProduct {
    pagebuilder {
      sections {
        ...AccordionFragment
        ...ArticleBannerFragment
        ...ArticleGridFragment
        ...AutoFAQFragment
        ...CollectionBannerFragment
        ...CollectionCTAFragment
        ...ContactFormFragment
        ...FullImageFragment
        ...ImageBannerFragment
        ...ImageLinkFragment
        ...NewsletterFragment
        ...PressGridFragment
        ...PressMarqueeFragment
        ...ProductGridFragment
        ...ReviewsFragment
        ...RichTextFragment
        ...ShareMarqueeFragment
        ...SmallTextFragment
      }
    }
  }
`;
