/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useWindowDimensions, useNewsletter, useSanityGlobals } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";
import { GRID_PADDING_REM } from "~components/_common/Grid/index.jsx";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Content = styled.div`
  width: calc(100% - ${GRID_PADDING_REM}rem);
  height: 16rem;
  position: relative;
  z-index: 40;
  padding: 0.667rem 0.667rem 0.667rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-yellow);
  color: var(--color-black);

  ${MEDIA_QUERIES?.desktop} {
    width: 38rem;
    height: 20rem;
    padding: 1rem;
  }
`;

const Form = styled.form`
  position: relative;
  display: flex;
  padding-bottom: 0.5rem;
`;

const FormInput = styled.input`
  width: 100%;
  height: 2.25rem;
  position: relative;
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  -webkit-appearance: none;

  &::-webkit-input-placeholder {
    color: var(--color-black);
  }

  &::-moz-placeholder {
    color: var(--color-black);
  }

  &:-ms-input-placeholder {
    color: var(--color-black);
  }

  &:-moz-placeholder {
    color: var(--color-black);
  }

  ${MEDIA_QUERIES?.desktop} {
    height: 2.5rem;
  }
`;

const FormSubmitContainer = styled.div`
  height: 2.25rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  ${MEDIA_QUERIES?.desktop} {
    height: 2.5rem;
  }
`;

const FormSubmit = styled.input`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  background: transparent;
  cursor: pointer;
  border: none;
  color: black;
`;

const CloseButton = styled.button`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
`;

const Newsletter = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isDesktop } = useWindowDimensions();

  const { newsletterMessage } = useSanityGlobals();

  const { newsletterActive, setNewsletterActive } = useNewsletter();

  const [form, setForm] = useState({});
  const [status, setStatus] = useState({
    submitting: false,
    submitted: false
  });

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    setForm({
      ...form,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (status?.submitting || status?.submitted) {
      return false;
    }

    const formData = { listId: `X6XEat`, ...form };

    setStatus({
      submitting: true,
      submitted: false
    });

    const response = await fetch(`/api/klaviyo`, {
      body: JSON.stringify(formData),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
      });

    setStatus({
      submitting: false,
      submitted: true
    });

    setTimeout(() => {
      setNewsletterActive(false);
    }, 1000);

    return false;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const handleClick = (e) => {
      if (e.target.matches(`.modal-close`) || !e.target.closest(`.modal`)) {
        setNewsletterActive(false);
      }
    };

    document.addEventListener(`click`, handleClick, false);

    return () => {
      document.removeEventListener(`click`, handleClick, false);
    };
  }, []);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper>
      <Content
        className="modal"
        css={css`
          transition: 0.5s var(--cubic-easing) opacity,
            0.5s var(--cubic-easing) transform;

          opacity: ${newsletterActive ? `1` : `0`};
          pointer-events: ${newsletterActive ? `auto` : `none`};
          transform: translate3d(0, ${newsletterActive ? `0` : `1rem`}, 0);
        `}
      >
        <p className="b1">
          <span
            dangerouslySetInnerHTML={{
              __html: !status?.submitted ? newsletterMessage : `Thank you!`
            }}
          />
        </p>

        <Form
          onSubmit={onSubmit}
          css={css`
            transition: 0.3s ease opacity;

            opacity: ${status?.submitting || status?.submitted ? 0.5 : 1};
            pointer-events: ${status?.submitting || status?.submitted
              ? `none`
              : `auto`};
          `}
        >
          <FormInput
            name="email"
            className="text-button"
            onChange={onChange}
            type="email"
            placeholder="Enter e-mail address"
          />

          <FormSubmitContainer>
            <FormSubmit className={isDesktop ? `b2` : `b3`} type="submit" />
          </FormSubmitContainer>
        </Form>

        <CloseButton
          type="button"
          className="modal-close"
          onClick={() => setNewsletterActive(false)}
        >
          <span className="text-cross">x</span>
        </CloseButton>
      </Content>
    </Wrapper>
  );
};

export default Newsletter;
