/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Go, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

/** ============================================================================
 * @component
 * Card link showing a product as a PDP link. By default the card will show the
 * first available variant, but this can be overridden by specifying one through
 * props.
 */
const ArticleCard = ({ article }) => {
  let i;

  // ---------------------------------------------------------------------------
  // context / ref / state

  //

  // ---------------------------------------------------------------------------
  // variables

  //

  // ---------------------------------------------------------------------------
  // lifecycle

  //

  // ---------------------------------------------------------------------------
  // render

  return (
    <article
      css={css`
        width: 100%;
        position: relative;
        display: block;
        padding-bottom: 2rem;
        text-align: center;

        ${MEDIA_QUERIES?.desktop} {
          padding-bottom: 4rem;
        }

        em {
          font-family: "Quadrant Text";
        }
      `}
    >
      <Go
        to={`/magazine/${article.slug.current}`}
        _css={css`
          .article-card-overlay {
            transition: 0.3s ease opacity;

            opacity: 0;
          }

          ${MEDIA_QUERIES?.hoverable} {
            &:hover {
              .article-card-overlay {
                opacity: 1;
              }
            }
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            position: relative;
            padding-bottom: 125%;
            display: block;
          `}
        >
          <div
            css={css`
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 10;
              background: var(--color-purple);
            `}
            className="article-card-overlay"
          />

          <figure
            css={css`
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
            `}
          >
            <Image
              _css={css`
                width: 100%;
                height: 100%;
                position: relative;
                display: block;
                object-fit: cover;
              `}
              image={article.cardImage}
            />
          </figure>
        </div>

        <h3
          css={css`
            margin: 0.75rem 0 0;
          `}
          className="h4"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: article?.formattedTitle || article.title
            }}
          />
        </h3>

        <p
          css={css`
            text-align: left;
          `}
          className="b3"
        >
          {article?.excerpt}
        </p>
      </Go>
    </article>
  );
};

export default ArticleCard;
