/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = ({ _css, image, alt, loading, title }) => {
  if (typeof image === `string`) {
    return (
      <>
        {(image.startsWith(`http`) || image.startsWith(`/`)) && (
          <img css={_css} src={image} alt={image} />
        )}
      </>
    );
  }

  const imageObj = image?.asset || image;

  if (!imageObj) {
    return <></>;
  }

  const imageData = getImage(imageObj);

  const src = imageData?.images?.fallback?.src;

  //

  return (
    <>
      {(image?.asset?.src?.includes(`.svg`) && (
        <img css={_css} src={src} alt={alt || ``} title={title || alt || ``} />
      )) || (
        <GatsbyImage
          css={_css}
          loading={loading || `eager`}
          image={imageData}
          alt={alt || ``}
          title={title || alt || ``}
        />
      )}
    </>
  );
};

export default Image;
